import axios from 'axios';

const API_ENDPOINT = '/api';

const API_BASE_URL =  process.env.VITE_API_BASE_URL || 'http://boughto-shopify.test'

/**
 * Fetch data from the provided URL.
 * @param {string} url - The URL to fetch data from.
 * @returns {Object|null} - The data fetched or null if there's an error.
 */
export const fetchData = async (url, data) => {
    try {
        const response = await axios.get(API_BASE_URL + API_ENDPOINT + url, {
            headers: {Accept: "application/json"}
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchCustomCss = async () => {
    return fetchData('/customise/css');
}

export const fetchWheelBrands = async () => {
    return fetchData('/wheel_brands');
}

export const fetchWheelRanges = async (brand_name) => {
    return fetchData('/wheel_brands/ranges/' + brand_name);
}

export const fetchWheelRange = async (range_id) => {
    return fetchData('/wheel_brands/range/' + range_id);
}

export const fetchWheelSearchByVehicleActionUrl = async () => {
    return fetchData('/customise/wheel_search_by_vehicle_action_url');
}

export const fetchManufacturers = async () => {
    return fetchData('/vehicle/manufacturers');
}

export const fetchModels = async (manufacturer_id) => {
    return fetchData('/vehicle/models/' + manufacturer_id);
}

export const fetchGenerations = async (model_id) => {
    return fetchData('/vehicle/generations/' + model_id);
}

export const fetchChassis = async (generation_id) => {
    return fetchData('/vehicle/engines/' + generation_id);
}

export const fetchSizes = async (chassis_id, wheel_range) => {
    if (wheel_range) {
        return fetchData('/search/wheel_sizes/' + chassis_id + '/' + wheel_range);
    }
    return fetchData('/search/wheel_sizes/' + chassis_id);
}

export const fetchLogoUrl = async () => {
    return API_BASE_URL + '/img/powered-by-boughto-grey.png';
}

export const fetchWheelResults = async (data) => {
    try {
        const response = await axios.post(API_BASE_URL + API_ENDPOINT + '/search/wheel_results', data, {
            headers: {"Content-Type": "multipart/form-data"}
        });

        if (response.data.pagination.current_page > 1 && response.data.results.length === 0) {
            data.page = 1;
            return fetchWheelResults(data);
        }

        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const fetchWheelPackage = async (data) => {
    try {
        const response = await axios.post(API_BASE_URL + API_ENDPOINT + '/wheel_package', data, {
            headers: {"Content-Type": "multipart/form-data"}
        });

        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const fetchWheelPackageBuy = async (data) => {
    try {
        const response = await axios.post(API_BASE_URL + API_ENDPOINT + '/wheel_package/buy', data, {
            headers: {"Content-Type": "multipart/form-data"}
        });

        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * Post cart items to retrieve detailed data.
 * @param {Object} data - The cart items data to post.
 * @returns {Object|null} - The response data or null if there's an error.
 */
export const postCartItems = async (data) => {
    try {
        const formData = new FormData();
        formData.append('cart_item', JSON.stringify(data));

        const response = await axios.post(API_BASE_URL + '/cartitems', formData, {
            headers: {"Content-Type": "multipart/form-data"}
        });

        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

/**
 * Post data to create a draft order.
 * @param {Object} data - The data to post for draft order creation.
 * @returns {Object|null} - The response data or null if there's an error.
 */
export const postDraftOrder = async (data) => {
    try {
        const response = await axios.post(API_BASE_URL + '/createDraftOrder', data, {
            headers: {"Content-Type": "application/json"}
        });

        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
